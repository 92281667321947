
import { defineComponent, onMounted, onActivated, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApilogisticsDoc } from "@/core/api";
import _ from "lodash";
import { formatDate, formatDateTime } from "@/core/directive/function/common";
import EditShipmentLabel from "./EditShipmentLabel.vue";
import mixin from "@/mixins";

interface LogisticsDocOriginalData {
  company_id: number;
  logistics_available: number;
  logistics_limit: number;
  logistics_total: number;
  request_document: {
    email: string;
    interior_category: Array<string>;
    notes: string;
    package_fee: number;
    package_num: number;
    package_size: Array<string>;
    package_type: string;
    package_weight: number;
    pick_up_date: string;
    recipient_address_id: number;
    sender_address_id: number;
    service_type: string;
  };
  status: number;
  warehouse_name: string;
}

export default defineComponent({
  name: "double-sale-order-shipment-label-detail",
  components: {
    EditShipmentLabel,
  },
  setup(props, context) {
    const { t } = useI18n();
    const route = useRoute();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const originalData = ref<LogisticsDocOriginalData>();

    /*
     *拉取数据
     */
    const getLogisticsDocShow = (id: string) => {
      loading.value = true;
      ApilogisticsDoc.getLogisticsDocShow({
        id: id,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            // 传回简介数据
            context.emit("getFormData", data.data);
            // 类型转换
            data.data.request_document.package_fee = Number(
              data.data.request_document.package_fee
            );
            data.data.request_document.package_num = Number(
              data.data.request_document.package_num
            );
            // 页面显示数据赋值
            originalData.value = data.data;

            getShowData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *转换显示
     */
    const showData = ref({
      logistics_limit: 0,
      company_name: "",
      sender_address: "",
      sender_address_short: "",
      recipient_address: "",
      recipient_address_short: "",
      service_type: "",
      pick_up_date: "",
      package_size_l: "",
      package_size_w: "",
      package_size_h: "",
      package_weight: 0,
      package_type: "",
      interior_category: "",
      interior_category_array: [""],
      email: "",
      notes: "",
    });
    const getShowData = async () => {
      showSync();
      showAsync();
    };
    // 同步回显
    const showSync = () => {
      // 告警阈值
      showData.value.logistics_limit = originalData.value
        ?.logistics_limit as number;
      // 提货日期
      showData.value.pick_up_date = formatDate(
        originalData.value?.request_document.pick_up_date as string,
        "DD-MMM-YYYY"
      );
      // 包裹尺寸 L / W / H
      showData.value.package_size_l = originalData.value?.request_document
        .package_size[0] as string;
      showData.value.package_size_w = originalData.value?.request_document
        .package_size[1] as string;
      showData.value.package_size_h = originalData.value?.request_document
        .package_size[2] as string;
      // 包裹重量
      showData.value.package_weight = originalData.value?.request_document
        .package_weight as number;
      // 邮件通知对象
      showData.value.email = originalData.value?.request_document
        .email as string;
      // 备注
      showData.value.notes = originalData.value?.request_document
        .notes as string;
    };
    // 异步回显
    const showAsync = async () => {
      loading.value = true;
      // 公司名称
      const showMerchant = async () => {
        const { data: merchantNameData } = await ApiBase.getMerchantSourceData({
          id: originalData.value?.company_id as number,
        });
        if (merchantNameData.code == 0) {
          showData.value.company_name = merchantNameData.data[0].label;
        } else {
          showServerErrorMsg(merchantNameData);
        }
      };
      // 寄件人
      const showSender = async () => {
        const { data: senderAddressData } =
          await ApilogisticsDoc.getAddressShow({
            id: originalData.value?.request_document
              .sender_address_id as number,
          });
        if (senderAddressData.code == 0) {
          showData.value.sender_address_short = `${senderAddressData.data.last_name} ${senderAddressData.data.first_name} ${senderAddressData.data.street}, ${senderAddressData.data.district}, ${senderAddressData.data.region}`;
          const showHTML = `
        ${senderAddressData.data.last_name}
        ${senderAddressData.data.first_name}<br>
        ${senderAddressData.data.zip_code}/
        ${senderAddressData.data.street},
        ${senderAddressData.data.district},
        ${senderAddressData.data.city}
        `;
          showData.value.sender_address = showHTML;
        } else {
          showServerErrorMsg(senderAddressData);
        }
      };
      // 收件人
      const showRecipient = async () => {
        const { data: recipientAddressData } =
          await ApilogisticsDoc.getAddressShow({
            id: originalData.value?.request_document
              .recipient_address_id as number,
          });
        if (recipientAddressData.code == 0) {
          showData.value.recipient_address_short = `${recipientAddressData.data.last_name} ${recipientAddressData.data.first_name} ${recipientAddressData.data.street}, ${recipientAddressData.data.district}, ${recipientAddressData.data.region}`;
          const showHTML = `
        ${recipientAddressData.data.last_name}
        ${recipientAddressData.data.first_name}<br>
        ${recipientAddressData.data.zip_code}/
        ${recipientAddressData.data.street},
        ${recipientAddressData.data.district},
        ${recipientAddressData.data.city}
        `;
          showData.value.recipient_address = showHTML;
        } else {
          showServerErrorMsg(recipientAddressData);
        }
      };
      // 服务类型、内件品类、包装类型
      const showTagging = async () => {
        const { data: taggingData } = await ApiBase.getTaggingData({
          short_key: [
            "package_packaging_type",
            "internal_parts_category",
            "package_service_type",
          ],
        });
        if (taggingData.code == 0) {
          const service_type = taggingData.data.package_service_type.items.find(
            (item) => {
              return (
                item.value == originalData.value?.request_document.service_type
              );
            }
          );
          if (service_type) {
            showData.value.service_type = service_type.label;
          }

          const package_type =
            taggingData.data.package_packaging_type.items.find((item) => {
              return (
                item.value == originalData.value?.request_document.package_type
              );
            });
          if (package_type) {
            showData.value.package_type = package_type.label;
          }
          if (originalData.value?.request_document.interior_category.length) {
            showData.value.interior_category_array.splice(
              0,
              showData.value.interior_category_array.length
            );
            for (
              let i = 0;
              i < taggingData.data.internal_parts_category.items.length;
              i++
            ) {
              for (
                let j = 0;
                j <
                originalData.value?.request_document.interior_category.length;
                j++
              ) {
                if (
                  taggingData.data.internal_parts_category.items[i].value ==
                  originalData.value?.request_document.interior_category[j]
                ) {
                  showData.value.interior_category_array.push(
                    taggingData.data.internal_parts_category.items[i].label
                  );
                }
              }
            }
            showData.value.interior_category =
              showData.value.interior_category_array.join(", ");
          }
        } else {
          showServerErrorMsg(taggingData);
        }
      };
      Promise.all([
        showMerchant(),
        showSender(),
        showRecipient(),
        showTagging(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    };

    const updateList = () => {
      getLogisticsDocShow(route.params.id as string);
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getLogisticsDocShow(route.params.id as string);
    });
    onActivated(() => {
      MenuComponent.reinitialization();
      getLogisticsDocShow(route.params.id as string);
    });

    return {
      t,
      loading,
      formatDate,
      formatDateTime,
      originalData,
      showData,
      updateList,
    };
  },
});
