
import { defineComponent, ref, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiBase, ApilogisticsDoc } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import ElSelect from "element-plus/es/components/select";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { MerchantOption, TaggingItem } from "@/core/directive/interface/common";

import PermissionCommon from "@/components/layout/PermissionCommon.vue";

interface AddressData {
  key: number;
  value: string;
}

interface formData {
  logistics_limit: number;
  company_id: number;
  sender_address_id: number;
  recipient_address_id: number;
  service_type: string;
  pick_up_date: string;
  package_size_l: number;
  package_size_w: number;
  package_size_h: number;
  package_weight: number;
  package_type: string;
  package_num: number;
  package_fee: number;
  interior_category: Array<string>;
  email: string;
  notes: string;
}

interface showData {
  logistics_limit: number;
  company_name: string;
  sender_address: string;
  sender_address_short: string;
  recipient_address: string;
  recipient_address_short: string;
  service_type: string;
  pick_up_date: string;
  package_size_l: string;
  package_size_w: string;
  package_size_h: string;
  package_weight: number;
  package_type: string;
  interior_category: string;
  interior_category_array: Array<string>;
  email: string;
  notes: string;
}

interface originalData {
  company_id: number;
  logistics_available: number;
  logistics_limit: number;
  logistics_total: number;
  request_document: {
    email: string;
    interior_category: Array<string>;
    notes: string;
    package_fee: number;
    package_num: number;
    package_size: Array<string>;
    package_type: string;
    package_weight: number;
    pick_up_date: string;
    recipient_address_id: number;
    sender_address_id: number;
    service_type: string;
  };
  status: number;
  warehouse_name: string;
}

export default defineComponent({
  name: "double-sale-order-shipment-label-update",
  components: {
    PermissionCommon,
  },
  props: {
    showData: {
      type: Object as () => showData,
      default: () => {
        return {
          logistics_limit: 0,
          company_name: "",
          sender_address: "",
          recipient_address: "",
          service_type: "",
          pick_up_date: "",
          package_size_l: "",
          package_size_w: "",
          package_size_h: "",
          package_weight: 0,
          package_type: "",
          interior_category: "",
          interior_category_array: [],
          email: "",
          notes: "",
        };
      },
    },
    originalData: {
      type: Object as () => originalData,
      default: () => {
        return {
          company_id: 0,
          logistics_available: 0,
          logistics_limit: 0,
          logistics_total: 0,
          request_document: {
            email: "",
            interior_category: [],
            notes: "",
            package_fee: 0,
            package_num: 0,
            package_size: [],
            package_type: "",
            package_weight: 0,
            pick_up_date: "",
            recipient_address_id: 0,
            sender_address_id: 0,
            service_type: 0,
          },
          status: 0,
          warehouse_name: "",
        };
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    const formRef = ref<null | HTMLFormElement>(null);
    const UpdateLogisticsDocRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref<formData>({
      logistics_limit: 0,
      company_id: 0,
      sender_address_id: 0,
      recipient_address_id: 0,
      service_type: "",
      pick_up_date: "",
      package_size_l: 0,
      package_size_w: 0,
      package_size_h: 0,
      package_weight: 0,
      package_type: "",
      package_num: 0,
      package_fee: 0,
      interior_category: [],
      email: "",
      notes: "",
    });

    /*
     *必填项验证
     */
    const rules = ref({
      logistics_limit: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      company_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      sender_address_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      recipient_address_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      service_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pick_up_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_size_l: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_size_w: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_size_h: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_weight: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_num: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      package_fee: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      interior_category: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          type: "email",
          message: t("shipmentLabel.emailVerifyTip"),
          trigger: "change",
        },
      ],
      notes: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    /*
     *下拉框配置
     */
    const options = ref({
      merchant: [] as MerchantOption[],
      merchantLoading: false,
      senderAddress: [] as AddressData[],
      senderAddressLoading: false,
      recipientAddress: [] as AddressData[],
      recipientAddressLoading: false,
      packageType: [] as TaggingItem[],
      packageTypeLoading: false,
      interiorCategory: [] as TaggingItem[],
      interiorCategoryLoading: false,
      serviceType: [] as TaggingItem[],
      serviceTypeLoading: false,
    });

    /*
     *下拉选择公司名称
     */
    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };
    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );
    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    /*
     *根据公司ID获取关联地址
     */
    const getAddressData = (id?, remake?) => {
      if (id) {
        getSenderAddressData(id, remake);
        getRecipientAddressData(id, remake);
      }
    };
    const getSenderAddressData = async (id?, remake?) => {
      if (remake) {
        // formData.value.sender_address_id = [];
        options.value.senderAddress = [];
      }
      options.value.senderAddressLoading = true;
      const { data } = await ApilogisticsDoc.getOwnerAddress({
        company_id: id,
        type: "sender_address",
      });
      options.value.senderAddressLoading = false;
      if (data.code == 0) {
        options.value.senderAddress = data.data;
      }
    };
    const getRecipientAddressData = async (id?, remake?) => {
      if (remake) {
        options.value.recipientAddress = [];
      }
      options.value.recipientAddressLoading = true;
      const { data } = await ApilogisticsDoc.getOwnerAddress({
        company_id: id,
        type: "recipient_address",
      });
      options.value.recipientAddressLoading = false;
      if (data.code == 0) {
        options.value.recipientAddress = data.data;
      }
    };

    /*
     *提交
     */
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          const submitData = formData.value;
          submitData["id"] = route.params.id as string;
          ApilogisticsDoc.updateLogisticsDoc(submitData)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                updateList();
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    /*
     *获取Tagging
     */
    const getTaggingData = () => {
      options.value.packageTypeLoading = true;
      options.value.interiorCategoryLoading = true;
      options.value.serviceTypeLoading = true;
      ApiBase.getTaggingData({
        short_key: [
          "package_packaging_type",
          "internal_parts_category",
          "package_service_type",
        ],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.packageType = data.data.package_packaging_type.items;
            options.value.interiorCategory =
              data.data.internal_parts_category.items;
            options.value.serviceType = data.data.package_service_type.items;
            options.value.packageTypeLoading = false;
            options.value.interiorCategoryLoading = false;
            options.value.serviceTypeLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *获取已填写数据
     */
    const getPropsData = () => {
      formData.value.logistics_limit = props.originalData.logistics_limit;
      options.value.merchant.push({
        label: props.showData.company_name,
        value: props.originalData.company_id,
        name: "",
        country_iso_2: "",
        address: "",
        entity_data: {
          commission: {
            max: "",
            min: "",
          },
          address: "",
          country_iso_2: "",
          name: "",
          __show: {
            country_iso_2: "",
            commission: "",
          },
        },
      });
      formData.value.company_id = props.originalData.company_id;
      options.value.senderAddress.push({
        key: props.originalData.request_document.sender_address_id,
        value: props.showData.sender_address_short,
      });
      formData.value.sender_address_id =
        props.originalData.request_document.sender_address_id;
      options.value.recipientAddress.push({
        key: props.originalData.request_document.recipient_address_id,
        value: props.showData.recipient_address_short,
      });
      formData.value.recipient_address_id =
        props.originalData.request_document.recipient_address_id;
      formData.value.pick_up_date =
        props.originalData.request_document.pick_up_date;
      formData.value.package_num =
        props.originalData.request_document.package_num;
      formData.value.package_size_l = Number(
        props.originalData.request_document.package_size[0]
      );
      formData.value.package_size_w = Number(
        props.originalData.request_document.package_size[1]
      );
      formData.value.package_size_h = Number(
        props.originalData.request_document.package_size[2]
      );
      formData.value.package_weight =
        props.originalData.request_document.package_weight;
      formData.value.package_type =
        props.originalData.request_document.package_type;
      formData.value.interior_category =
        props.originalData.request_document.interior_category;
      formData.value.package_fee =
        props.originalData.request_document.package_fee;
      formData.value.service_type =
        props.originalData.request_document.service_type;
      formData.value.notes = props.originalData.request_document.notes;
      formData.value.email = props.originalData.request_document.email;
    };

    /*
     *关闭弹框
     */
    const resetForm = () => {
      formData.value = {
        logistics_limit: 0,
        company_id: 0,
        sender_address_id: 0,
        recipient_address_id: 0,
        service_type: "",
        pick_up_date: "",
        package_size_l: 0,
        package_size_w: 0,
        package_size_h: 0,
        package_weight: 0,
        package_type: "",
        package_num: 0,
        package_fee: 0,
        interior_category: [],
        email: "",
        notes: "",
      };
    };
    const handleDiscard = () => {
      hideModal(UpdateLogisticsDocRef.value);
    };
    const updateList = () => {
      handleDiscard();
      emit("update-list");
    };
    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getTaggingData(), getMerchantSourceData(""), getPropsData()])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    onMounted(() => {
      modalShowListener(UpdateLogisticsDocRef.value, () => {
        getFromInfo();
      });
      modalHideListener(UpdateLogisticsDocRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      props,
      loading,
      formData,
      rules,
      formRef,
      UpdateLogisticsDocRef,
      submitButton,
      options,
      submit,
      resetForm,
      searchMerchantSourceItems,
      getAddressData,
      handleDiscard,
    };
  },
});
